/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../css/app.css');
require('../css/app.scss');

// loads the jquery package from node_modules
var $ = require('jquery');
require('bootstrap');
var Cookies = require('js-cookie');

$(document).ready(function() {
    $('#desktop-menu-button').on('click', function(e){
        e.preventDefault();
        $('#desktop-menu-holder').toggleClass('closed');
        $('#desktop-contact-btn').toggleClass('closed');
        $('.burger-container.desktop .nav-icon2').toggleClass('open');
    })

    $('#mobile-menu-button').on('click', function(e){
        e.preventDefault();
        $('#mobile-menu-holder').toggleClass('opened');
        $('.burger-container.mobile').toggleClass('closed');
        $('.burger-container.mobile .nav-icon2').toggleClass('open');
    })

    if(Cookies.get('user_submited_mail_address')) {
        var object = Cookies.get('user_submited_mail_address');
        // console.log(object);
        // console.log($.parseJSON(object));
    }

    window.onscroll = function (e) {
        var scrollTop = $(window).scrollTop() ;

        if (scrollTop == 0) {
            $('header').removeClass('scroll');
            $('#desktop-contact-btn').removeClass('closed');
            $('#desktop-menu-holder').removeClass('closed');
        } else {
            $('header').addClass('scroll');
            $('#desktop-contact-btn').addClass('closed');
            $('#desktop-menu-holder').addClass('closed');
        }

        $('.sharing-container').fadeOut(300);
        $('.share-icon').fadeIn(300);
    }

    // close modal
    $('#closing-x').on("click", function (event) {
        $('#contact-popup').fadeOut(500);
    });

    $("#contact-popup").on('click', function(e) {
        if (e.target === this){
            $(this).fadeOut();
        }
    });

    // open modal
    $(".js-open-contact-popup").on("click", function (event) {
        event.preventDefault();
        $('#programid').val('');
        $('#formType').val('');

        var haveCookie = Cookies.get('user_submited_mail_address');

        var buttonName = $(this).attr('data-buttonName');
        $('#formType').val(buttonName);








        if (buttonName === 'footer-brochure-btn') {
            $('#js-message-sent').html('Votre demande bien été prise en compte.<br>Vous allez recevoir un email de confirmation avec la brochure.');

            // if (haveCookie) {
                window.open('/downloads/2740PALL_BrochureInstit_190419.pdf', '_blank').focus();
                $('#js-message-sent').html('Le document a bien été téléchargé.');




                gtag('event', 'submit', {
                    'event_category': 'click_brochure',
                    'event_label': 'click_brochure'
                });
                gtag('event', 'conversion', {'send_to': 'AW-745988994/a6jNCPzJuKYBEILH2-MC',
                    'value': 10.0
                });


            $('#brown-box h3, #brown-box form, #brown-box .obligatoires').hide();
                $('#brown-box input[type="text"], #brown-box input[type="email"]').hide();
                $('#js-message-sent').show();
                $('#contact-popup').fadeIn(500);
            // }

        } else {

            if (buttonName === 'news-brochure-btn') {
                var programid = $(this).attr('data-programid');
                $('#js-message-sent').html('Votre demande bien été prise en compte.<br>Vous allez recevoir un email de confirmation avec la brochure.');

                var brochurename = $(this).attr('data-brochurename');
                $('#brochurename').val(brochurename);

                if (haveCookie) {
                    window.open('/downloads/' + brochurename, '_blank').focus();
                    $('#js-message-sent').html('Le document a bien été téléchargé.');

                    var object = Cookies.get('user_submited_mail_address');
                    object = $.parseJSON(object);

                    gtag('event', 'submit', {
                        'event_category': 'formulaire_brochure',
                        'event_label': object.hashEmail
                    });
                    $('#contact-popup').fadeIn(500);
                    gtag('event', 'conversion', {
                        'send_to': 'AW-745988994/3e79CKKKvKYBEILH2-MC',
                        'value': 50.0
                    });


                }
            } else if (buttonName === 'program') {
                var programid = $(this).attr('data-programid');
                $('#js-message-sent').html('Votre demande bien été prise en compte.<br>Vous allez recevoir un email de confirmation.');
                $('#programid').val(programid);

                if (haveCookie) {
                    $('#js-message-sent').html('Votre nouvelle demande a bien été prise en compte. <br>Vous allez recevoir un email de confirmation.');

                    var object = Cookies.get('user_submited_mail_address');
                    object = $.parseJSON(object);
                    gtag('event', 'submit', {
                        'event_category': 'formulaire_programme',
                        'event_label': object.hashEmail
                    });
                    gtag('event', 'conversion', {
                        'send_to': 'AW-745988994/-ShYCPawwaYBEILH2-MC',
                        'value': 100.0
                    });
                }
            }

            if (!haveCookie) {
                $('#brown-box h3, #brown-box form, #brown-box .obligatoires').show();
                $('#brown-box input[type="text"], #brown-box input[type="email"]').val('');
                $('#brown-box input[type="checkbox"]').prop('checked', false);
                $('#js-message-sent').hide();

                $('#contact-popup').fadeIn(500);

            } else {

                var object = Cookies.get('user_submited_mail_address');
                object = $.parseJSON(object);

                var name =  object.name;
                var email = object.email;
                var phone = object.phone;
                var firstcheckbox = object.firstcheckbox;
                var secondcheckbox = object.secondcheckbox;

                $('#additional-name').val(name);
                $('#additional-email').val(email);
                $('#additional-phone').val(phone);

                if (firstcheckbox) {
                    $('#additional-popup #firstcheckbox').attr('checked', true);
                }

                if (secondcheckbox) {
                    $('#additional-popup #secondcheckbox').attr('checked', true);
                }

                $('#brown-box h3, #brown-box form, #brown-box .obligatoires').hide();
                $('#brown-box input[type="text"], #brown-box input[type="email"]').hide();
                $('#js-message-sent').show();

                if (buttonName === 'program') {
                    $('#contact-popup').fadeIn(500);

                    var formData = $('#additional-popup').serializeArray();

                    $.ajax({
                        type: 'POST',
                        url: '/sendAdditionalForm',
                        data: formData,
                        success: function (retour) {
                            if (retour.result == "ok") {
                                $('#contact-popup').fadeIn(500);
                            }
                        },
                        dataType: 'json'
                    });
                }
            }
        }
    });

    // sending forms
    $('#additional-popup').on('submit', function(e){
        e.preventDefault();

        var buttonName = $('#formType').val();
        var formData = $('#additional-popup').serializeArray();

        $.ajax({
            type: 'POST',
            url: '/sendAdditionalForm',
            data: formData,
            success: function (retour) {
                if (retour.result == "ok") {
                    $('#brown-box h3, #brown-box form, #brown-box .obligatoires').hide();
                    $('#js-message-sent').show();

                    var name = $('#additional-name').val();
                    var email = $('#additional-email').val();
                    var phone = $('#additional-phone').val();
                    var firstcheckbox = $('#additional-popup #firstcheckbox').val();
                    var secondcheckbox = $('#additional-popup #secondcheckbox').val();

                    Cookies.set('user_submited_mail_address', '{ "name": "' + name + '" , "email": "' + email + '" , "phone": "' + phone + '" , "firstcheckbox": "' + firstcheckbox + '" , "secondcheckbox": "' + secondcheckbox + '" , "hashEmail": "' + retour.hashEmail + '"}', { expires: 7 });

                    if (buttonName === 'footer-brochure-btn' || buttonName === 'news-brochure-btn') {
                        gtag('event', 'submit', {
                            'event_category': 'formulaire_brochure',
                            'event_label': retour.hashEmail
                        });

                        // Facebook event tracking :
                        fbq('track', 'Lead');
                        // console.log('tag facebook');

                        gtag('event', 'conversion', {
                            'send_to': 'AW-745988994/3e79CKKKvKYBEILH2-MC',
                            'value': 50.0
                        });


                    } else if (buttonName === 'program') {

                        gtag('event', 'submit', {
                            'event_category': 'formulaire_programme',
                            'event_label': retour.hashEmail
                        });
                        // console.log('tag analytics formulaire_programme' + retour.hashEmail);

                        // Facebook event tracking :
                        fbq('track', 'Lead');
                        // console.log('tag facebook formulaire_programme');

                        gtag('event', 'conversion', {
                            'send_to': 'AW-745988994/-ShYCPawwaYBEILH2-MC',
                            'value': 100.0
                        });

                    }
                }
            },
            dataType: 'json'
        });
    })

    $(document).on('click', function(e){
        var customtarget = e.target;
        if(!customtarget.classList.contains('disabled-closing')) {
            $('.sharing-container').fadeOut(300);
            $('.share-icon').fadeIn(300);
        }
    });

    $('#phone-btn').on('click', function(e){
        console.log('test footer')
        gtag('event', 'submit', {
            'event_category': 'click_tel',
            'event_label': 'click_tel'
        });
        gtag('event', 'conversion', {
            'send_to': 'AW-745988994/tmzsCPHTuKYBEILH2-MC',
            'value': 30.0
        });
    });
});